import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import Button from './Button'
import InfoAlert from './InfoAlert'

const validateField = (field, errors) => {
  return errors[field] && <span className="text-red-500 text-sm">{errors[field].message}</span>
}

function encode(data) {
  return Object.keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&')
}

function sendMessage(values) {
  return fetch(`/?t=${+new Date()}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: encode({ 'form-name': 'request-demo', ...values }),
  })
}

export default ({ onFormSubmitted }) => {
  const { handleSubmit, register, errors, formState } = useForm()
  const [formSent, setFormSent] = useState(false)
  const onSubmit = async values => {
    const response = await sendMessage(values).catch(err => console.log(err))
    if (response.status === 200) {
      setFormSent(true)
      if (typeof onFormSubmitted === 'function') onFormSubmitted()
    }
  }
  return (
    <form
      name="request-demo"
      onSubmit={handleSubmit(onSubmit)}
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
          Name
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="name"
            name="name"
            type="text"
            placeholder="Name"
            ref={register({
              required: 'Name is required',
            })}
          />
          {validateField('name', errors)}
        </label>
      </div>
      <div className="mb-6">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
          Email
          <input
            name="email"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="your@email.com"
            ref={register({
              required: 'Email is required',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Invalid email address',
              },
            })}
          />
          {validateField('email', errors)}
        </label>
      </div>
      <div className="flex items-center justify-between">
        <Button type="submit" disabled={formState.isSubmitting}>
          Request a Demo
        </Button>
      </div>
      {formSent && (
        <InfoAlert>
          <p className="font-bold">Your information has been submitted!</p>
          <p className="text-sm">You will be contacted soon by one expert of our team.</p>
        </InfoAlert>
      )}
    </form>
  )
}
