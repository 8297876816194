import React from 'react'
import { Location } from '@reach/router'
import Fade from 'react-reveal/Fade'
import Layout from '../components/layout/Layout'
import Button from '../components/Button'
import Bullet from '../components/Bullet'
import EmailForm from '../components/EmailForm'

export default () => (
  <Layout>
    <section className="container mx-auto px-8 pl-10 lg:flex">
      <div className="w-full lg:w-1/2">
        <Fade>
          <h1 className="text-5xl text-center">Try FinStar today!</h1>
          <h2 className="text-2xl text-center">Embrace the Digital Transformation</h2>
        </Fade>
        <div class="pt-4">
          <Fade top cascade>
            <ul>
              <li className="flex items-center">
                <Bullet />
                See why businesses choose FinStar to manage their mission-critical capabilities for
                cash, risk management, payments and working capital optimization.
              </li>
              <li className="flex items-center">
                <Bullet />
                Protect your business against loss from risk.
              </li>
              <li className="flex items-center">
                <Bullet />
                Reduce costs through automation of keys tasks.
              </li>
              <li className="flex items-center">
                <Bullet />
                Enforce regulatory compliance on a global scale.
              </li>
              <li className="flex items-center">
                <Bullet />
                Drive growth opportunities through improved decision support.
              </li>
            </ul>
          </Fade>
        </div>
      </div>

      <div className="w-full lg:w-1/2">
        <Fade right>
          <div className="ml-4 max-w-sm shadow-lg rounded px-8 pt-6 pb-8 mb-4 bg-white">
            <Location>
              {({ navigate }) => (
                <EmailForm onFormSubmitted={() => setTimeout(() => navigate('/'), 5000)} />
              )}
            </Location>
          </div>
        </Fade>
      </div>
    </section>
    <section className="container mx-auto px-8 md:pt-12 pl-10 lg:flex">
      <div className="w-full">
        <p className="text-sm py-10">
          Submit the form to discuss your needs and questions with one of our experts.
        </p>
        <Button to="/">Back</Button>
      </div>
    </section>
  </Layout>
)
